import { useQuery } from "@apollo/client"
import { RefObject, useRef, useState, useEffect, useMemo } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  AhoyEventTypeEnum,
  PostCreateInput,
  Selector_ChannelFragment,
} from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { FeedSidebar } from "~/feed/FeedSidebar"
import { PostFeed } from "~/feed/PostFeed"
import { TagFilter } from "~/feed/TagFilter"
import { useTagFilter } from "~/feed/useTagFilter"
import { PageWithRightSidebar } from "~/layouts/PageWithRightSidebar"
import { ComposerContextProvider } from "~/post-composer/ComposerContext"
import {
  ComposerHandle,
  ComposerOnSave,
  PostComposer,
} from "~/post-composer/PostComposer"
import { Error } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { channelPath } from "~/common/paths"
import { useDebounce } from "use-debounce"
import { SearchInput } from "~/ui/SearchInput"
import { useLogEvent } from "~/analytics/EventsContext"
import { NewPostsIndicator } from "~/feed/NewPostsIndicator"
import { PostInViewportProvider } from "~/feed/PostInViewportContext"
import { UnreadPostsIndicator } from "~/feed/UnreadPostsIndicator"

export const FeedScreen = () => {
  const composerRef = useRef<ComposerHandle>(null)

  return (
    <ComposerContextProvider composerRef={composerRef}>
      <PageWithRightSidebar sidebar={<FeedSidebar />}>
        <FeedScreenInner composerRef={composerRef} />
      </PageWithRightSidebar>
    </ComposerContextProvider>
  )
}

const FeedScreenInner = ({
  composerRef,
}: {
  composerRef: RefObject<ComposerHandle>
}) => {
  const { channelSlug } = useParams()
  const { selectedTagIds, setSelectedTagIds } = useTagFilter()
  const [channel, setChannel] = useState<Selector_ChannelFragment | null>(null)
  const [retweetPostId, setRetweetPostId] = useState<string | null>(null)
  const [retweetArticleId, setRetweetArticleId] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400)
  const topOfFeedRef = useRef<HTMLDivElement>(null)
  const queryVariables = useMemo(
    () => ({
      channelSlug,
      selectedTagIds,
      search: debouncedSearchTerm,
    }),
    [channelSlug, selectedTagIds, debouncedSearchTerm]
  )
  const {
    data: currentData,
    previousData,
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(FEED_QUERY_DOCUMENT, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const { logEvent } = useLogEvent()
  const navigate = useNavigate()

  const data = currentData || previousData
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!channelSlug) {
      setChannel(null)
      return
    }

    if (data && !loading && !error) {
      setChannel(
        data.channels.nodes.find((c) => c.slug === channelSlug) || null
      )
    }
  }, [channelSlug, data, loading, error, setChannel])

  useEffect(() => {
    const postId = searchParams.get("share_post_id")
    const articleId = searchParams.get("share_article_id")
    if (postId) setRetweetPostId(postId)
    if (articleId) setRetweetArticleId(articleId)
  }, [searchParams])

  useEffect(() => {
    if (data && !loading) {
      if (composerRef && composerRef.current) {
        if (searchParams.get("post") === "focus") {
          composerRef.current.focus()
          searchParams.set("post", "focused")
          setSearchParams(searchParams)
        }
      }
    }
  }, [composerRef, searchParams, setSearchParams, loading, data])

  const [runPostCreate, postCreateResult] =
    useSafeMutation(POST_CREATE_MUTATION)

  const createPost: ComposerOnSave = async (input: PostCreateInput) => {
    const { data, errors } = await runPostCreate({
      variables: {
        input,
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
      return false
    } else {
      toast.success("Created post")
      invariant(data?.postCreate.post.id)

      const mentionedUsers = data.postCreate.post.mentionedUsers || []

      logEvent(AhoyEventTypeEnum.PostSubmitted, {
        topics_selected: data.postCreate.post.tag?.name,
        post_id: data.postCreate.post.id,
        channel: data.postCreate.post.channel?.name,
        post_id_retweeted: data.postCreate.post.retweetPostId,
        article_id_retweeted: data.postCreate.post.retweetArticleId,
        is_anon: input.anonymous ? true : false,
        tagged_entities: mentionedUsers.map((u) => {
          return { id: u.id, type: "user" }
        }),
        number_entities_tagged: mentionedUsers.length,
      })

      // switch channel if the post was placed in a different channel
      if (
        channel &&
        data.postCreate.post.channel &&
        data.postCreate.post.channel.id !== channel.id
      ) {
        navigate(
          channelPath({ channelSlug: data.postCreate.post.channel.slug })
        )
      }

      setRetweetArticleId(null)
      setRetweetPostId(null)
      setSelectedTagIds([])
      refetch({ selectedTagIds: [] })

      return true
    }
  }

  const postComposerPost = useMemo(
    () => ({ channel, retweetArticleId, retweetPostId }),
    [channel, retweetArticleId, retweetPostId]
  )

  if (!data && loading)
    return (
      <div className="mt-8 flex items-center text-foreground">
        <LoadingIndicator /> Loading posts...
      </div>
    )
  if (error || !data) return <Error message="Error loading feed." />

  const posts = data.posts.edges.map((e) => e.node)

  return (
    <PostInViewportProvider>
      <div className="pb-16 w-full">
        <PostComposer
          tags={data.tags}
          onSave={createPost}
          isSaving={postCreateResult.loading}
          title={channel?.name || "Community Feed"}
          ref={composerRef}
          key={`composer-${channel?.slug}`}
          post={postComposerPost}
          allowAnonymous
        />

        <div className="md:grid md:grid-rows-none md:grid-cols-2 flex flex-col gap-4 my-4">
          <div className="text-xs tracking-wide flex items-center">
            <SearchInput
              placeholder="Search posts..."
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              className="rounded-2xl h-full"
              inputClassName="rounded-2xl text-xs font-medium"
            />
          </div>
          <TagFilter
            allTags={data.tags}
            selectedTagIds={selectedTagIds}
            setTagIds={setSelectedTagIds}
          />
        </div>

        {(!channelSlug || channel?.id) && (
          <NewPostsIndicator
            channelId={channel?.id}
            queryVariables={queryVariables}
            onClick={() =>
              topOfFeedRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          />
        )}
        {channelSlug && <UnreadPostsIndicator channel={channel} />}

        <div className="relative">
          <div ref={topOfFeedRef} className="absolute -top-5" />
        </div>

        <PostFeed
          posts={posts}
          isChannel={!!channelSlug}
          isFiltered={selectedTagIds.length > 0}
          trackReadStates
        />

        <InfiniteLoadMore
          onEndReached={() =>
            fetchMore({
              variables: { postsCursor: data.posts.pageInfo.endCursor },
            })
          }
          canLoadMore={!loading && data.posts.pageInfo.hasNextPage}
          loadingText="Loading posts..."
          loading={loading && posts.length > 0}
        />
      </div>
    </PostInViewportProvider>
  )
}

export const FEED_QUERY_DOCUMENT = gql(`
  query FeedScreen($selectedTagIds: [ID!], $channelSlug: String, $search: String, $postsCursor: String) {
    channels {
      ...ChannelConnection_Composer
    }
    tags {
      ...TagConnection_Composer
      ...TagConnection_Filter
    }
    posts(selectedTagIds: $selectedTagIds, channelSlug: $channelSlug, search: $search, first: 20, after: $postsCursor) {
      edges {
        node {
          ...Post_Card
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)

const POST_CREATE_MUTATION = gql(`
  mutation PostCreate($input: PostCreateInput!) {
    postCreate(input: $input) {
      post {
        id
        retweetPostId
        retweetArticleId
        mentionedUsers {
          id
        }
        tag {
          name
        }
        channel {
          id
          name
          slug
        }
      }
    }
  }
`)
